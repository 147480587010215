body{
	background: #472415 url(../images/page_bg.png) repeat-x center top;
	font: 92%/140% Georgia, "Times New Roman", Times, serif;
}

.container{
	max-width: 924px;
	padding: 0;
}

.entry-content p{
	word-break: break-word;
}

.pgcsimplygalleryblock-grid-item-menu-holder{
	display: none !important;
}

.search-count{
	display: none;
}

a{
	color: #9c0000;
	&:hover{
		color: #bb1015;
	}
}

#menu-mainmenu li a {
	color: #fff;
	background: url(../images/menubar.png) 100% 0 no-repeat;
	height: 42px;
  line-height: 40px;
  padding: 0px 15px;
	font-size: 14px;
}

#menu-mainmenu li.active a,
#menu-mainmenu .current-category-ancestor a,
#menu-mainmenu .current-post-ancestor a{
	color: #e3daa1 !important;
}

#menu-mainmenu li:not(.active) a:hover{
background: url(../images/menubar_over.png) no-repeat right top;
	height: 42px;
	color: #eee;
}

#menu-mainmenu .current-category-ancestor a:hover,

#menu-mainmenu .current-category-ancestor a:hover,
#menu-mainmenu .current-post-ancestor a{
	background: url(../images/menubar.png) 100% 0 no-repeat !important;
}

#header-featured{
background: #fff;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	height: 30px;
  line-height: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #3d2518;
	background-size: cover;
	padding: 0 16px;
	font-size: 14px;
	left: -25px;
	position: relative;
	cursor: pointer;
}

.wrap.container .content{
	background: #FFFEF1;
    border-radius: 6px;
    box-shadow: inset 0px 0px 22px 9px #988552;
	margin-top: 5px;
	padding-bottom: 40px;
}

.wrap.container{
	box-shadow:0px 0px 10px 3px #00000085;
	max-width: 914px;
}

.sidebar .widgettitle{
	font-size: 16px;
	background: #600D07;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	color:#fefef1;
	padding: 6px 10px;
	margin-bottom: 2px;
}

.sidebar{
	margin-top: 30px;
  margin-left: 30px;
}

.sidebar > section{
	background: #EBE8D4;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	box-shadow: 0px 0px 4px 2px #0000003d;
}

#menu-leftmenu-1{
	list-style-type: none;
  padding: 0 7px;
	padding-bottom: 7px !important; 
}

#menu-leftmenu-1 li a{
	color: #9C0000;
	height: 32px;
  padding: 4px 0 4px 10px;
	line-height: 28px !important;
	font: bold 12px Verdana, Arial, Helvetica, sans-serif;
	background: transparent url(../images/arrowgreen.gif) 100% 0;
	display: block;
	text-decoration: unset !important;
}

#menu-leftmenu-1 li a:hover{
	background-position: 100% -32px;
	color: #BB1015;
}

#menu-leftmenu-1 li{
	border-left: 1px solid #94AA74;
	border-right: 1px solid #94AA74;
}

#menu-leftmenu-1 li:first-child{
	border-top: 1px solid #94AA74;
}

.main{
	margin-top: 30px;
	padding-left: 15px;
	padding-right: 20px;
}

.page-id-79 .main .page-header h1{
	border-bottom: 1px solid #e9e5d0 !important;
  color: #8c0d09 !important;
  font-size: 20px;
  line-height: 145%;
	font-weight: 100 !important;
}

.archive .main .page-header h1,
.search .main .page-header h1{
	color: #472415;
}

.wsp-category-title,
.wsp-posts-title,
.wsp-pages-title{
	display: none;
}

.wsp-container > strong > .wsp-posts-list > li:not(.wsp-container > strong > .wsp-posts-list > li:nth-child(3)){
	display: none;
}

.wsp-container > strong > .wsp-posts-list > li:first-child::marker{
	color: transparent;
}

.wsp-container > strong > ul{
	padding-left: 20px;
}

.wsp-posts-list ul{
	padding-left: 0;
}

.wsp-container li a{
	color: #9c0000;
   text-decoration: underline;
	font-weight: 100;
	line-height: 1.5;
}

footer .widget p,
footer .widget a{
	color: #fff;
	font: 10px/14px Arial, Helvetica, Geneva, sans-serif;
}

footer .widget p{
	text-align: center;
	padding-top: 8px;
}

#header-featured:not(.page-home-page-data 
#header-featured){
	display: none;
}

.page-home-page-data .page-header{
	display: none;
}

.home-page-top-section{
	background: url(../images/home-background.png) left center no-repeat;
	background-size: contain;
}

.home-page-top-section h3{
	font-size: 16px;
	color: #a10000;
}

.home-page-top-section{
	padding-right: 64px;
    padding-left: 40px;
    padding-top: 35px;
    height: 312px;
    font-size: 13px;
    line-height: 1.4;
		margin-top: -5px;
		margin-right: -15px;
}

.custom-post-title{
	font-size: 20px;
    color: #470705;
    margin-top: 18px;
    border-bottom: 1px solid #e9e5d0;
    padding-bottom: 4px;
	width: 100%;
}

.custom-post-author,
.custom-post-published,
.custom-post-updated{
	font: small-caps 10px/10px "Lucida Grande", Lucida, Verdana, sans-serif;
    color: #b1a89e;
	margin-bottom: 10px;
	width: 100%;
}

.featured-posts-list{
		display: flex;
    gap: 20px;
    flex-wrap: wrap;
    height: 200px;
    padding-top: 15px;
    
}

.featured-item{
	display: flex;
	flex-direction: column;
	width: 17%;
}

.featured-item img{
  max-height: 70px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.featured-item a{
	color: #9c0000;
  text-decoration: underline;
	font-size: 13px;
	text-align: center;
}

.hidden-featured-section:before{
	content: '';
	background: url(../images/cork_top.png);
	height: 47px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: -1;
}

.hidden-featured-section:after{
	content: '';
	background: url(../images/cork_bottom.png);
	height: 47px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: -1;
	bottom: 0;
}

.hidden-featured-section{
	background: url(../images/cork.png);
  z-index: 1;
	background-size: 100% 10%;
	position: relative
}

.featured-posts-list{
		height: 165px;
    overflow: auto;
    padding: 15px 14px 25px 60px;
    position: absolute;
    top: 26px;
		max-width: 96%;
}

.hidden-featured-section{
	position: relative;
	height: 215px !important;
}

.featured-posts-list::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

.featured-posts-list::-webkit-scrollbar-track {
  background: #dedee5;
}

.featured-posts-list::-webkit-scrollbar-thumb {
  background: #71120A;
  border-radius: 0;
}

nav,
.hero-logo,
.wrap.container{
	z-index: 999;
	position: relative;
}

.wrap.container .content{
	margin-top: -210px;
	transition: all 0.5s ease;
}

.wrap.container .content.active{
	margin-top: 10px;
	transition: all 0.5s ease;
}

.page-header h1{
	font-size: 20px !important;
    line-height: 30px !important;
    font-weight: bold !important;
    color: #472415 !important;
    border: 0 !important;
}

.entry-title a,
.entry-title{
 color: #9c0000;
  text-decoration: underline;
  font-size: 14px;
}

.archive .post .updated,
.archive .post .byline.author,
.search .post .updated,
.search .post .byline.author{
	display: none;
}

.archive .entry-summary,
.search .entry-summary{
	display: flex;
	max-width: 70%;
	margin-bottom: 10px;
}

.archive .entry-summary img,
.search .entry-summary img{
  max-width: 25%;
	position: absolute;
  right: 10px;
  top: 10px;
  height: auto;
}

.archive .content article,
.search .content article{
	width: calc(50% - 2px);
  border: 2px solid #472415;
  margin: 1px;
  padding: 10px;
	position: relative;
	min-height: 110px;
	display: grid;
}

.content main{
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
}

.content main .page-header,
.content main .pagination{
	width: 100%;
}

.page-numbers.current{
	color: #9e9984;
	text-decoration: none !important;
}

.page-numbers{
	color: #9c0000;
  text-decoration: underline;
	font-weight: 600;
}

.pagination{
	display: flex;
    justify-content: center;
    margin-top: 12px;
    column-gap: 5px;
}

.page-numbers.dots{
	text-decoration: unset;
}

.pagination a:hover{
	color: #9c0000 !important;
}

.post-image-count{
	color: #999999;
}

.archive-top-bar,
.archive-top-bar a{
	color: #9c0000;
  text-decoration: underline;
	font-size: 14px;
}

.search-toggle{
	cursor: pointer;
}

.archive-top-bar{
	margin-bottom: 20px;
	width: 100%;
  display: flex;
	margin-left: 8px;
}

.archive-top-bar a{
	cursor: pointer;
}

.other-resorces-link{
	margin-right: 12px;
}

.archive-top-bar .add-entry{
	margin-left: 12px;
}

.search-form{
	width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
	align-items: flex-start;
	display: none;
}

.search-form.active{
	display: flex;
}

.search-form .search-field{
	border: 1px solid #767676 !important;
  padding: 4px;
  height: 22px;
  color: #000;
	font-size: 14px;
	border-radius: 2px;
}

.search-form .search-field:focus{
	-webkit-box-shadow: unset !important;
    box-shadow: unset !important;
	 border: 2px solid #000 !important;
}


.search-form .search-submit{
	border: 1px solid #767676;
  padding: 4px;
  height: 22px;
  color: #000;
	font-size: 14px;
	background: #EFEFEF;
	line-height: 10px;
	margin-left: 5px;
	border-radius: 2px;
}

.search-form .search-submit:hover,
.search-form .search-submit:active{
	background: #ebe9e9;
	color: #000;
}

.search-form .search-field::placeholder{
	color: #000;
	font-size: 14px;
	text-transform: lowercase;
}

.single-post .entry-title, .single-collection .entry-title{
	line-height: 24px;
  font-size: 22px;
  color: #9b1b0f;
  letter-spacing: -1px;
  font-weight: 600;
  text-decoration: unset;
	margin-bottom: 25px;
}

.single-post .updated,
.single-post .byline.author{
	display: none;
}

#print{
	width: 54px;
  height: 54px;
  background: unset;
  border: 0;
	padding: 0;
	display: block;
	margin-left: auto;
	margin-right: 20px;
}

#divPrint{
	padding-left: 20px;
}

#print img{
	width: 100%;
}

.single-collection h2, .single-post h2{
	font-size: 25px;
  line-height: 145%;
  padding: 4px;
  color: #623b27;
  letter-spacing: -1px;
  font-weight: 700;
}

.single-collection h3, .single-post h3{
	font-size: 20px;
  line-height: 80%;
  padding: 4px;
  color: #000;
  letter-spacing: -1px;
	font-weight: 700;
}

.back_button{
	background: url('../images/back_button.png') no-repeat center center;
	width: 63px;
  height: 25px;
  margin: 15px 0px 0px 0px;
  text-indent: -8000em;
  display: block;
	cursor: pointer;
}

.post-page-category{
	font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  color: #472415;
	margin-bottom: 5px;
}

.page-id-1883 .dave-page-title a{
	border-bottom: 1px solid #e9e5d0;
  color: #470705;
	font-size: 20px;
	width: 100%;
	display: block;
	line-height: 145%;
}

.page-id-1883 .custom-post-updated{
	margin-top: 15px;
	margin-bottom: 40px;
}

.more-dave-blog{
	color: #3d2518;
	font-size: 15px;
	font-weight: bold;
	width: 100%;
	display: block;
}

.remaining-posts{
	width: 100%;
	padding: 10px;
	border-top: 1px solid #e7e8da;
}

.remaining-posts h2 a{
	padding: 4px;
  border-bottom: 1px solid #dadacc !important;
	color: #9c0000 !important;
	display: block;
	font-size: 14px !important;
	line-height: 1.4 !important;
}

.remaining-posts .custom-post-author,
.remaining-posts .custom-post-published,
.remaining-posts .custom-post-content,
.remaining-posts .custom-post-updated{
	display: none;
}

.hidden,
.category-other-resources-of-interest .military-branch,
.category-other-resources-of-interest .post-image-count{
	display: none !important;
}

.category-45 .post .entry-summary{
	max-width: 100% !important;
}

.category-other-resources-of-interest .entry-summary a{
	color: #9c0000;
  text-decoration: underline;
  font-size: 14px;
	text-align: right;
	display: inline-block;
	width: 100%;
	margin-bottom: 5px;
}

.category-other-resources-of-interest .entry-summary *{
	width: 100%;
}

.single-post .category-other-resources-of-interest #print{
	display: none;
}

.single-collection .post, .single-post .post {
	height: 100%;
	width: 100%;
}


.single-collection .category-other-resources-of-interest #divPrint, 
.single-post .category-other-resources-of-interest #divPrint{
	border: 3px solid #472415;
    width: 95%;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
		margin-bottom: 50px;
		display: block;
}

.single-collection .category-other-resources-of-interest .entry-content a,
.single-post .category-other-resources-of-interest .entry-content a{
	color: #9c0000;
  text-decoration: underline;
  font-size: 14px;
	margin-bottom: 10px;
	display: block;
}

.sgb-gallery{
	display: grid !important;
   grid-template-columns: repeat(3, 3fr);
	gap: 15px;
}

.sgb-gallery .sgb-item{
	margin: 0 !important;
}

@media(min-width: 951px){
	.mobile-menu,
	nav .block-3{
		display: none;
	}
	
	
}

@media(max-width: 950px){
	.hero-logo{
		height: 33vw !important;
    background: url(../images/ww1-mobile-logo.jpg) no-repeat center top !important;
    background-size: contain !IMPORTANT;
	}
	
	nav{
		background: linear-gradient(180deg, rgba(57,10,6,1) 0%, rgba(116,20,11,1) 100%) !important;
    margin: 0px 10px 0 10px;
       height: unset !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
		flex-direction: column;
		overflow: hidden;
    max-height: 60px !important;
		transition: all 0.5s ease;
		overflow: auto;
	}
	
	.nav-primary{
		overflow: hidden;
	}
		
	.nav-primary.active{
		overflow: auto;
		max-height: 300px !important;
		transition: all 0.5s ease;
	}
	
	#header-featured{
		margin-top: -2px;
		position: absolute;
    right: 10px;
    left: unset;
    width: 140px;
    text-align: center;
	}
	
	body > .container{
		width: unset;
    margin: 0 5px;
	}
	
	.hidden-featured-section:before,
	.hidden-featured-section:after,
	.hidden-featured-section{
		background: unset !important;
	}
	
	.featured-posts-list{
		padding: 15px 14px 25px 14px !important;
    max-width: 100% !important;
    margin: 0 40px;
	}
	
	.featured-posts-list {
    padding: 5px 10px 5px 10px !important;
    margin: 0 40px;
    background: #FFFEF1;
    border: 10px solid #BDB591;
		width: calc(100% - 80px);
		justify-content: center;
}
	
	.featured-item {
    width: 135px;
}
	
	.home-page-top-section {
    border: 12px solid #fff;
    margin: 0px;
    margin-right: 0px !important;
    height: auto !important;
		padding-right: 15px;
    padding-left: 15px;
    padding-top: 25px;
		background: unset !important;
	}
	
	.sidebar{
		display: none;
	}
	
	.main{
		width: 100% !important;
		padding-left: 30px;
    padding-right: 30px;
	}
	
	#menu-mainmenu li a{
		background: unset !important;
	}
	
	#menu-mainmenu{
		flex-direction: column;
		padding-bottom: 10px;
		margin-top: -8px;
	}
	
	.home-page-top-section img{
		margin-right: 80px;
	}
	
	.mobile-menu img{
		max-width: 100%;
		 -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
	}
	
	.mobile-menu{
		max-width: 38px;
    margin: 12px;
	}
	
	.archive .content article, .search .content article{
		width: calc(100% - 2px);
	}
	
	.hidden-featured-section .featured-posts-list:not(.home .hidden-featured-section .featured-posts-list){
		display: none !important;
	}
	
	.archive-top-bar{
		gap: 12px;
		flex-wrap: wrap;
	}
	
	.other-resorces-link{
		margin-right: 0;
	}
	
	.archive-top-bar .add-entry{
		margin-left: 0;
	}
	
	#print{
		position: absolute;
		top: 32px;
    right: 14px;
	}
	
	#divPrint{
		padding-left: 0;
	}
	
	.search main > .col-xl-12{
		padding-left: 0px;
		padding-right: 0px;
	}
	
	nav .block-3 .widgettitle{
		font-size: 14.5px;
    color: #fff;
	}
	
	nav .block-3{
		margin-left: 15px;
		margin-top: 8px;
	}
	
	#menu-leftmenu{
		list-style-type: none;
    padding-left: 10px;
    row-gap: 13px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    max-height: 0;
    overflow: hidden;
    margin: 0;
		transition: all 0.5s ease;
	}
	
	nav .block-3 .widgettitle::after{
		content: '>';
    color: #fff;
    font-size: 12px;
    font-weight: 900;
    margin-left: 12px;
    transform: rotate(90deg);
    display: inline-block;
		transition: all 0.5s ease;
	}
	
	#menu-leftmenu.active{
		transition: all 0.5s ease;
		max-height: 200px;
		margin-bottom: 15px;
		transition: all 0.5s ease;
	}
	
	#menu-leftmenu a{
		color: #fff !important;
		font-size: 13px;
	}

}

@media(max-width: 550px){
	.featured-posts-list {
    margin: 0px 15px;
    width: calc(100% - 30px);
}
	
	.sgb-gallery{
	display: grid !important;
   grid-template-columns: repeat(2, 3fr);
	gap: 10px;
}
}

.soldier_meta {
    margin-bottom: 30px;
}