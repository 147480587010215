#project-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3px !important;
  width: 100%;

  .gallery-col {
    padding: 5px;
    @include flex(0, 0, 50%);
    @include tablet {
    @include flex(0, 0, 33.333%);
    }
  
    @include laptop {
    padding: 15px;
    @include flex(0, 0, 25%);
    }
  }
  
  .gallery-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: block;
    box-shadow: 0 1px 4px 0 rgba($black, 0.3);
    
    @include aspect-ratio(1, 1);
  
    img {
      display: none;
      }
    
      &::after,
      i {
      will-change: visibility, opacity;
      visibility: hidden;
      opacity: 0;
    
      @include links-transition(all, 0.3s, ease-in-out);
    }
    
    &::after {
      content: '';
      background-color: $brand-primary;
      
      @include stretch;
    }
    
    i {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      z-index: 300;
      color: $white;
      font-size: 1.5em;
    }
    
    &:hover {
      &::after {
        visibility: visible;
        opacity: 0.3;
      }
      
      i {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}